// Colours
$dn-text-color: #222;
$dn-bg-color: #ddd;
$dn-bg-color-2: #eee;
$dn-primary-color: #000000;
$dn-secondary-color: #FFF1E3;
$dn-success-color: #45af4a;
$dn-fail-color: #bf1827;
$dn-form-element-color: #7f7f7f;
$dn-white: #FFF;
$dn-black: #000;

// Transitions
$main-transition-time: 200ms;
$main-transition-type: ease-in-out;

@mixin icomoon-font() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 16) * 1px;
    font-size: $sizeValue * 1em;
}

// Center block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Clearfix
@mixin clearfix() {
    content: "";
    display: table;
    table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
    clear: both;
}

@mixin radius($rad : 50%){
	-webkit-border-radius: $rad;
	-moz-border-radius: $rad;
	border-radius: $rad; 
}

@mixin translateY($trans:0%){
	transform: translateY($trans);
	-moz-transform: translateY($trans);
	-o-transform: translateY($trans);
	-webkit-transform: translateY($trans);
	-ms-transform: translateY($trans);
}

@mixin translateX($trans:0%){
	transform: translateX($trans);
	-moz-transform: translateX($trans);
	-o-transform: translateX($trans);
	-webkit-transform: translateX($trans);
	-ms-transform: translateX($trans);
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin transition($transition: all $main-transition-time $main-transition-type){
    transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
}

@mixin bp($point) {
    @media (max-width: $point) {
        @content;
    }
}

@mixin bpm($point) {
    @media (min-width: $point) {
        @content;
    }
}

@mixin bp-between($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin bph($point) {
    @media (max-height: $point) {
        @content;
    }
}

@mixin remove-leading-margins {
    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
}

@mixin standard-container-padding {
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: 991px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (max-width: 767px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

@mixin standard-content-padding {
    padding-top: 8rem;
    padding-bottom: 8rem;
    @media (max-width: 767px) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

@mixin standard-side-padding {
    @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@mixin intro-content-margin {
    margin-bottom: 3rem;
}

@mixin bp-print {
    @media print {
        @content
    }
}